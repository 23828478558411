import React, {useEffect, useState} from "react";

import style from "./COM101F1.module.scss"; // CSS 모듈 불러오기

import FadeInText from "components/Effect/FadeInText"

// 이미지 배열 (6개의 이미지 경로 또는 URL)
const images = [
    require("../../assets/images/sec01_box_001.png"),
    require("../../assets/images/sec01_box_002.png"),
    require("../../assets/images/sec01_box_003.png"),
    require("../../assets/images/sec01_box_004.png"),
    require("../../assets/images/sec01_box_005.png"),
    require("../../assets/images/sec01_box_006.png"),
    require("../../assets/images/sec01_box_007.png"),
    require("../../assets/images/sec01_box_008.png"),
];

const COM101F1 = () => {

    const [boxes, setBoxes] = useState([]);

    const boxWidth = 340; // 박스 너비
    const boxSpacing = 30; // 박스 간 간격
    const totalBoxWidth = boxWidth + boxSpacing; // 박스 간격 포함 전체 너비

    const baseDuration = 144; // 화면 통과 기준 시간 (초)

    const screenWidth = typeof window !== "undefined" ? window.innerWidth : 0; // 시작시 화면 사이즈

    const [initialBoxCount, setInitialBoxCount] = useState(Math.ceil(screenWidth / totalBoxWidth) + 10);

    useEffect(() => {

        const initialBoxes = Array.from({ length: initialBoxCount }, (_, i) => {

            const remainingDistance = screenWidth + i * totalBoxWidth;
            const initDuration = (remainingDistance / screenWidth) * baseDuration; // 박스별 속도 계산

            return {
                id: `box-${i}`,
                image: images[i % images.length],
                initialX: remainingDistance, // 화면 끝에서 시작
                currentX: remainingDistance,
                currentY: ["-10%", "0%", "10%", "0%", "-10%",][i % 5],
                duration: initDuration ,    // 박스별 이동 속도
            }
        });

        setBoxes(initialBoxes);


        /*
          박스가 삭제 처리되기 때문에 카운트가 증가 하지 않음.
          다른 상태로 구성 필요함.
        */
        const interval = setInterval(() => {

            setInitialBoxCount(initialBoxCount+1);

        }, baseDuration); // 2초마다 새로운 박스 추가

        return () => clearInterval(interval); // 컴포넌트 언마운트 시 인터벌 정리

    }, []);

    // 박스 삭제 처리
    useEffect(() => {
        const interval = setInterval(() => {
            setBoxes((prevBoxes) =>
                prevBoxes.filter((box) => box.currentX + (totalBoxWidth*3) > 0) // 화면 밖으로 나간 박스 삭제
            );
        }, baseDuration); // 2초 마다 상태 점검

        return () => clearInterval(interval); // 컴포넌트 언마운트 시 인터벌 정리
    }, []);

    return (
        <div className={style.sec01_root}>
            <div className={style.sec01_ctn}>
                <div className={style.title_box}>
                    <FadeInText className={style.main_title} animationType="fade-in-right">
                        HEALTH SCAN
                    </FadeInText>
                    <FadeInText className={style.sub_title} animationType="fade-in-up">
                        혈압 및 혈당관리부터 건강검진기록까지, 내 손안의 건강정보 데이터 통합 관리 플랫폼
                    </FadeInText>
                </div>
                <div className={style.box_container}>
                    {/*{boxes.map((box, index) => (*/}
                    {/*    <motion.div*/}
                    {/*        key={box.id}*/}
                    {/*        className={style.box}*/}
                    {/*        initial={{x: box.initialX - (screenWidth * 1.5)}}  // 시작위치*/}
                    {/*        animate={{*/}
                    {/*            x: -box.initialX - (screenWidth * 1.5), // 오른쪽 끝 → 왼쪽 끝까지 이동*/}
                    {/*            y: ["0%", "-10%", "10%", "0%"], // 파도 효과*/}
                    {/*        }}*/}
                    {/*        transition={{*/}
                    {/*            x: {*/}
                    {/*                ease: "linear", // 일정한 속도*/}
                    {/*                duration: box.duration, // 박스별 이동 시간*/}
                    {/*            },*/}
                    {/*            y: {*/}
                    {/*                duration: 2, // 상하 반복 타임*/}
                    {/*                repeat: Infinity, // 무한 반복*/}
                    {/*                ease: "easeInOut", // 부드러운 상하 이동*/}
                    {/*                delay: index * 0.3, // 박스 간 시간차*/}
                    {/*            },*/}
                    {/*        }}*/}
                    {/*        style={{*/}
                    {/*            left: `${totalBoxWidth}px`, // 박스 간 간격*/}
                    {/*        }}*/}
                    {/*        onUpdate={(latest) => { // 임시*/}
                    {/*            setBoxes((prevBoxes) =>*/}
                    {/*                prevBoxes.map((b) =>*/}
                    {/*                    b.id === box.id ? {...b, currentX: latest.x} : b*/}
                    {/*                )*/}
                    {/*            );*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        <div>*/}
                    {/*            /!*{Math.round(box.currentX)}<br/>*!/*/}
                    {/*            {box.id}*/}
                    {/*        </div>*/}
                    {/*        <img*/}
                    {/*            src={box.image}*/}
                    {/*            alt={`box-${box.id}`}*/}
                    {/*            className={style.image}*/}
                    {/*            onError={(e) => (e.target.src = "default_image_path.png")} // 이미지 로드 실패 처리*/}
                    {/*        />*/}
                    {/*    </motion.div>*/}
                    {/*))}*/}

                    {boxes.map((box, index) => (
                        <div className={style.box}
                             style={{left: `${box.currentX - (screenWidth * 1.5)}px`, top: `${box.currentY}`}}>
                            {/*<div>*/}
                            {/*    {Math.round(box.currentX)}<br/>*/}
                            {/*    {box.currentY}<br/>*/}
                            {/*    {box.id}*/}
                            {/*</div>*/}
                            <img
                                src={box.image}
                                alt={`box-${box.id}`}
                                className={style.image}
                                onError={(e) => (e.target.src = "default_image_path.png")} // 이미지 로드 실패 처리
                            />
                        </div>
                    ))}
                </div>
                <div className={style.sec01_scroll}></div>
            </div>
        </div>
        );
    };

export default COM101F1;