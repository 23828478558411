
import React  from "react"
import styled from "styled-components"

const colorStyle = {
    default: {
        color: '#3B79FE',
        borderWidth: '1px',
        borderColor: '#3B79FE',
        background: '#fff',
        hoverBackground: '#EBF2FF',
        hoverBorder: '#1px solid #3B79FE'
    },
    primary: {
        color: '#FFF',
        borderWidth: '0px',
        borderColor: 'none',
        background: '#3B79FE',
        hoverBackground: '#6193ff',
        hoverBorder: 'none'
    },
    success: {
        color: '#fff',
        borderRadius: '9px',
        borderWidth: '0px',
        borderColor: 'none',
        background: '#BABABAFF',

        boxShadow: '0 4px 20px -2px rgba(0, 0, 0, 0.25)'
    }
};

const StyledButton = styled.button`

    display: flex;
    justify-content: center;
    align-items: center; 
    
    column-gap: ${props => ({'sm': '4px', 'md': '5px', 'lg': '6px', 'xl': '7px'}[props.$size])};

    width: ${props => props.$addStyle.width};
    height: ${props => props.$addStyle.height};

    color: ${props => ! props.$addStyle.color ? colorStyle[props.$variant].color : props.$addStyle.color};
    
    line-height: ${props => ({'sm': '160%', 'md': '160%', 'lg': '180%', 'xl': '180%'}[props.$size])};
    font-size: ${props => ({'sm': '16px', 'md': '18px', 'lg': '20px', 'xl': '22px'}[props.$size])};
    font-weight: ${props => ({'sm': '500', 'md': '500', 'lg': '600', 'xl': '700'}[props.$size])};
        
    font-style: normal;        
    font-family: 'Pretendard';
            
    border-radius: 6px;
    border-style: solid;
    
    border-width: ${props => props.$addStyle.borderWidth? props.$addStyle.borderWidth : colorStyle[props.$variant].borderWidth };
    border-color: ${props => colorStyle[props.$variant].borderColor};
            
    background: ${props => colorStyle[props.$variant].background};

    //transition: background-color 0.3s ease, border-color 0.3s ease; /* 애니메이션 천천히 */
    transition: all 0.3s ease;
    &:hover{
        border: ${props => colorStyle[props.$variant].hoverBorder}; 
        background: ${props => colorStyle[props.$variant].hoverBackground};
        box-shadow: ${props => colorStyle[props.$variant].boxShadow};
    }
    
    
    
`;


const Button = props => {

    const {variant, size, addStyle={}, onClick} = props;

    return (
        <StyledButton
            $variant={variant}
            $size={size}
            $addStyle={addStyle}
            onClick={onClick}
        >
            {props.children}
        </StyledButton>
    )
}
export default Button


