// src/pages/common/COM001F1.jsx
import React from 'react';


import style from './COM002F1.module.scss';


const COM002F1 = () => {
    return(
        <div className={style.footer}>
            <div className={style.footer_ctn}>
                <div className={style.logoBox}>{/*HEALTH SCAN 타이틀*/} </div>

                <div className={style.companyInfo}>
                    <div className={style.infoBox01}>
                        (주식회사)틸더 (TILDE)<br/>
                        서울특별시 성북구 정릉로 161, 고려대학교 메디사이언스파크 내
                    </div>
                    <div className={style.infoBox02}>
                        <span>사업자등록번호 : 236-86-02071</span>
                        <span className={style.partition}>|</span>
                        <span>Contact us : tilde_cs@tilde.co.kr</span>
                    </div>
                </div>


            </div>
        </div>
    )
}
export default COM002F1;