// src/pages/common/COM001F1.jsx
import React, {useRef, useState} from 'react';


import style from './COM001F1.module.scss';
import Icon from "../../components/Icon/Icon";


const COM001F1 = () => {

    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const timeoutRef = useRef(null);

    const showPopup = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current); // 기존 타이머 제거
        }
        setIsPopupVisible(true);
    };

    const hidePopupWithDelay = () => {
        timeoutRef.current = setTimeout(() => {
            setIsPopupVisible(false);
        }, 200); // 200ms 지연
    };

    const handleClick_download = async () => {
        const fileUrl = require("../../assets/download/241219_Installer.zip");

        try {
            // 파일 데이터 가져오기
            const response = await fetch(fileUrl);
            if (!response.ok) {
                throw new Error("File not found");
            }

            // Blob 객체 생성
            const blob = await response.blob();

            // Blob을 다운로드 링크로 변환
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "HEALTH SCAN_MED_Installer.zip"; // 저장될 파일 이름
            document.body.appendChild(a);
            a.click();

            // Cleanup
            a.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Download failed:", error);
            alert("파일을 다운로드할 수 없습니다.");
        }
    };

    const handleClick_scrollToPosition = () => {
        window.scrollTo({
            top: 5000, // 원하는 Y축 위치 (픽셀 단위)
            behavior: "smooth", // 부드러운 스크롤
        });
    };



    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={style.header}>
            <div className={style.header_ctn}>
                <div className={style.portal_logo}></div>
                <div className={style.info_box}>
                    <div
                        onMouseEnter={showPopup}
                        onMouseLeave={hidePopupWithDelay}
                    >
                        <span onClick={() => window.open("https://med.healthscan.co.kr")}>
                            HEALTH SCAN MED
                        </span>
                        {isPopupVisible && (
                            <div className={style.popup}
                                 onMouseEnter={showPopup}
                                 onMouseLeave={hidePopupWithDelay}
                            >
                                <span onClick={handleClick_download}>다운로드</span>
                            </div>
                        )}
                    </div>

                    <span onClick={handleClick_scrollToPosition}>Contact us</span>
                </div>
                <div className={style.info_box_m}>
                    <button className={style.menuBtn} onClick={toggleMenu}>
                        <Icon id="burgerBtn" width="24" height="24"/>
                    </button>

                    {/* 슬라이드 메뉴 */}
                    <div
                        className={`${style.modal} ${isOpen ? style.open : ""}`}
                        onClick={toggleMenu} // 모달 외부를 클릭 시 닫기
                    >
                        <div
                            className={style.menuCont}
                            onClick={(e) => e.stopPropagation()} // 메뉴 내부 클릭 시 닫히지 않도록
                        >
                            <button className={style.closeBtn} onClick={toggleMenu}>
                                <Icon id="closeX" width="24" height="24"/>
                            </button>
                            <nav>
                                <ul>
                                    <li onClick={() => window.open("https://med.healthscan.co.kr")}>HEALTH SCAN MED</li>
                                    <li onClick={handleClick_scrollToPosition}>Contact us</li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default COM001F1;