import React, {useRef, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react"

import Icon from "components/Icon/Icon";

import style from "./COM101F3.module.scss"; // CSS 모듈 불러오기

import { useScrollFadeIn } from "hooks/useControlFadeIn";

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const COM101F3 = () => {

    const backAnimatedItem = useScrollFadeIn('',1.5);

    const swiperRef = useRef(null);

    const handleSlideTo = (index) => {
        if (swiperRef.current) {
            swiperRef.current.slideTo(index); // 특정 슬라이드로 이동
        }
    };

    const [slides, setSlides] = useState([
        {title:"건강기록 대시보드",content:`메인에서 혈압, 혈당, BMI등을 <span>한 눈에 파악가능하고</span><br/><span>손쉽게 건강 기록 추가</span>가 가능해요.`},
        {title:"건강검진 및 진료/검사 기록관리",content:`잠들어 있는 <span>건강검진 결과 통합관리로</span><br/><span>변화된 개인생활습관</span>을 확인할 수 있어요.`},
        {title:"투약기록 및 약품정보 통합관리",content:`개인별 <span>의약품 투약내역</span>을 통해<br/>의약품 <span>부작용 및 알러지 정보</span> 등 확인이 가능해요.`},
        {title:"가계기반 마이패밀리",content:`가계기반의 <span>부모, 배우자, 형제/자매 및 자녀</span>까지<br/>본인인증을 통한 <span>건강정보 공유</span>가 가능해요.`},
        {title:"영유아 건강관리",content:`영유아 자녀에 대한 <span>영유아검진, 예방접종,</span><br/><span>진료/검사, 투약기록</span> 연계가 가능해요.`},
        {title:"개인 맞춤형 서비스",content:`인공지능을 통한 <span>증상별 진료과 추천</span>과 내 위치 기준의<br/> <span>실시간 병원과 약국 정보</span>를 조회할 수 있어요.`},
        {title:"유용한 건강 정보 제공",content:`실생활에 도움이 될 <span>의료 정보를 담은 카드뉴스,<br/></span> 신뢰할 수 있는 <span>칼럼과 건강 뉴스 기사</span>를 제공해요.`},
    ]);

    return (
        <div className={style.sec03_ctn} {...backAnimatedItem}>
            <div className={style.sec03_title_box}>{/*타이틀이미지*/}</div>
              <div className={style.sec03_content}>
                <div className={style.slide_box}>
                    <Swiper className={style.swiper}
                        key="sec03_swiper"
                        onSwiper={(swiper) => {
                            swiperRef.current = swiper; // Swiper 인스턴스 저장
                        }}
                        spaceBetween={50}
                        slidesPerView={1}
                    >
                        {slides.map((slide, index) => {

                            const pageNo = index+1;

                            return (
                            <SwiperSlide className={style.swiperSlide} >
                                <div className={style.slide_cont}>
                                    <div className={style.cont_textBox}>
                                        <h3>{String(pageNo).padStart(2,'0')}</h3>
                                        <h4>{slide.title}</h4>
                                        <p dangerouslySetInnerHTML={{__html: slide.content}}/>
                                        <div className={style.nav_bar}>
                                            <button onClick={() => handleSlideTo(index-1)}><Icon id="arrow_left_01" width="32" height="32"/></button>
                                            <span>{String(pageNo).padStart(2,'0')}</span>  { "/ "+String(slides.length).padStart(2, '0') }
                                            <button onClick={() => handleSlideTo(pageNo)}><Icon id="arrow_left_02" width="32" height="32"/></button>
                                        </div>
                                    </div>
                                    <div className={style.cont_img}>
                                        <div className={style[`cont_img_bg${String(pageNo).padStart(2,'0')}`]}>{/*HealthScan앱 이미지*/}</div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            )}
                        )}
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default COM101F3;
