import React, {useEffect, useState} from "react";

import style from "./COM201F1.module.scss";

import FadeInText from "components/Effect/FadeInText"

// 이미지 배열 (6개의 이미지 경로 또는 URL)
const images = [
    require("../../assets/images/sec01_box_m_001.png"),
    require("../../assets/images/sec01_box_m_002.png"),
    require("../../assets/images/sec01_box_m_003.png"),
    require("../../assets/images/sec01_box_m_004.png"),
    require("../../assets/images/sec01_box_m_005.png"),
    require("../../assets/images/sec01_box_m_006.png"),
    require("../../assets/images/sec01_box_m_007.png"),
    require("../../assets/images/sec01_box_m_008.png"),
];

const COM201F1 = () => {

    const [boxes, setBoxes] = useState([]);

    const boxWidth = 160; // 박스 너비
    const boxSpacing = 8; // 박스 간 간격
    const totalBoxWidth = boxWidth + boxSpacing; // 박스 간격 포함 전체 너비

    const baseDuration = 144; // 화면 통과 기준 시간 (초)

    const screenWidth = typeof window !== "undefined" ? window.innerWidth : 0; // 시작시 화면 사이즈

    const [initialBoxCount, setInitialBoxCount] = useState(Math.ceil(screenWidth / totalBoxWidth) + 1);

    useEffect(() => {

        const initialBoxes = Array.from({ length: initialBoxCount }, (_, i) => {

            const remainingDistance = screenWidth + i * totalBoxWidth;
            const initDuration = (remainingDistance / screenWidth) * baseDuration; // 박스별 속도 계산

            return {
                id: `box-${i}`,
                image: images[i % images.length],
                initialX: remainingDistance, // 화면 끝에서 시작
                currentX: remainingDistance,
                //currentY: ["-10%", "0%", "10%", "0%", "-10%",][i % 5],
                duration: initDuration ,    // 박스별 이동 속도
            }
        });

        setBoxes(initialBoxes);


        /*
          박스가 삭제 처리되기 때문에 카운트가 증가 하지 않음.
          다른 상태로 구성 필요함.
        */
        // const interval = setInterval(() => {
        //
        //     setInitialBoxCount(initialBoxCount+1);
        //
        // }, baseDuration); // 2초마다 새로운 박스 추가
        //
        // return () => clearInterval(interval); // 컴포넌트 언마운트 시 인터벌 정리

    }, []);

    // 박스 삭제 처리
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setBoxes((prevBoxes) =>
    //             prevBoxes.filter((box) => box.currentX + (totalBoxWidth*3) > 0) // 화면 밖으로 나간 박스 삭제
    //         );
    //     }, baseDuration); // 2초 마다 상태 점검
    //
    //     return () => clearInterval(interval); // 컴포넌트 언마운트 시 인터벌 정리
    // }, []);

    return (
        <div className={style.sec01_root}>
            <div className={style.sec01_ctn}>
                <div className={style.title_box}>
                    <FadeInText className={style.main_title} animationType="fade-in-right">
                        HEALTH SCAN
                    </FadeInText>
                    <FadeInText className={style.sub_title} animationType="fade-in-up">
                        혈압 및 혈당관리부터 건강검진기록까지, 내 손안의 건강정보 데이터 통합 관리 플랫폼
                    </FadeInText>
                </div>
                <div className={style.box_container}>
                    {boxes.map((box, index) => (
                        <div className={style.box}
                             style={{left: `${box.currentX - (screenWidth * 1.5)}px`, top: `${box.currentY}`}}>
                            <img
                                src={box.image}
                                alt={`box-${box.id}`}
                                className={style.image}
                                onError={(e) => (e.target.src = "default_image_path.png")} // 이미지 로드 실패 처리
                            />
                        </div>
                    ))}
                </div>
                <div className={style.sec01_scroll}></div>
            </div>
        </div>
    );
};

export default COM201F1;