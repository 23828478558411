import React, { useEffect, useState, useRef } from "react";
import styles from "./FadeIn.module.scss"; // fadeIn 관련 스타일이 있는 SCSS 파일

const FadeInText = ({ children, animationType = "fade-in-up", style, className }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [animationDuration, setAnimationDuration] = useState(1); // 기본 애니메이션 속도
    const ref = useRef();

    useEffect(() => {
        if (ref.current) {
            // 외부 스타일에서 font-size 가져오기
            const fontSize = window.getComputedStyle(ref.current).fontSize;
            const sizeInPx = parseInt(fontSize, 10); // font-size 값 추출 (px 단위)

            // 애니메이션 속도 더 빠르게 하기 위해 계산식 수정
            setAnimationDuration(Math.max(0.3, 2 - sizeInPx / 120)); // 크기에 비례한 속도, 하한 0.3s로 빠르게 설정
        }
    }, [children]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.1 }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    return (
        <div
            ref={ref}
            style={{
                ...style,
                animationDuration: `${animationDuration}s`, // 동적으로 계산된 애니메이션 속도 적용
            }}
            className={`${styles[animationType]} ${isVisible ? styles.visible : ""} ${className || ""}`}
        >
            {children}
        </div>
    );
};

export default FadeInText;
