// components/Toast/Toast.jsx

import React from 'react';
import { toast, ToastContent } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import style from './Toast.scss';


const Toast = props => {

    // 파라미터 셋팅
    const {
        type,
        message,
        opt = null
    } = props;

    const option =
        {   position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            theme: "light",
            ...opt
        }

    const ToastComponent = () => (
        <div className="toastMsgBox">
            {message}
        </div>
    );

    switch (type) {
        case 'info':
            toast.info(<ToastComponent />,option);
            break;
        case 'success':
            toast.success(<ToastComponent />,option);
            break;
        case 'warning':
            toast.warning(<ToastComponent />,option);
            break;
        case 'error':
            toast.error(<ToastComponent />,option);
            break;
        default:
            toast(<ToastComponent />,option);
            break;
    }
};

export default Toast;