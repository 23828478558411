// config/axiosConfig.jsx
import axios from 'axios';

export const axiosInstance = axios.create({
    //baseURL: "http://localhost:8080",
    timeout: 3000,
    headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
    },
    responseType: "json",
});

/* = 에러 처리 인터셉터 ======================== */
axiosInstance.interceptors.response.use(
    (response) => response, // 성공 응답 처리
    async (error) => {
        const originalRequest = error.config; // 원래 요청상태 저장

        const status = error.response?.status;

        alert("오류가 발생 했습니다."+status)

        return Promise.reject(error);
    }
);





