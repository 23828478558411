import React, {useEffect, useState} from "react";

import style from "./COM001L1.module.scss"; // CSS 모듈 불러오기
import COM101F1 from "pages/COM/COM101F1";
import COM101F2 from "pages/COM/COM101F2";
import COM101F3 from "pages/COM/COM101F3";
import COM101F4 from "pages/COM/COM101F4";
import COM101F5 from "pages/COM/COM101F5";
import COM101F6 from "pages/COM/COM101F6";

import COM201F1 from "pages/COM/COM201F1";
import COM201F2 from "pages/COM/COM201F2";
import COM201F3 from "pages/COM/COM201F3";
import COM201F4 from "pages/COM/COM201F4";
import COM201F5 from "pages/COM/COM201F5";


const COM001L1 = () => {


    const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 640);
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className={style.root_wrap}>

            {isMobile ? (
                <>
                    <COM201F1 />
                    <COM201F2 />
                    <COM201F3 />
                    <COM201F4 />
                    <COM201F5 />
                    {/*<COM201F6 />*/}
                    <COM101F6 isMobile={isMobile}/>
                </>
            ) : (
                <>
            {/*페이지 01*/}
            <div className={style.sec01_wrap}>
                <COM101F1/>
            </div>
            {/*페이지 02*/}
            <div className={style.sec02_wrap}>
                <COM101F2/>
            </div>
            {/*페이지 03*/}
            <div className={style.sec03_wrap}>
                <COM101F3/>
            </div>

            {/*페이지 04*/}
            <div className={style.sec04_wrap}>
                <COM101F4/>
            </div>

            {/*페이지 05*/}
            <div className={style.sec05_wrap}>
                <COM101F5/>
            </div>

            {/*페이지 06*/}
            <div className={style.sec06_wrap}>
                <COM101F6 isMobile={isMobile}/>
            </div>
                </>
            )}
        </div>
    );
};

export default COM001L1;
