import React, {useState} from "react";

import styled from "styled-components"

import style from "./COM101F4.module.scss"; // CSS 모듈 불러오기
import Masonry from 'react-masonry-css';
import {useScrollFadeIn} from "hooks/useControlFadeIn";

const StdTitle = styled.h3`
    
    font-size: 18px;
    font-weight: 600;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    
    &::before{
        content: '';
        display: inline-block;
        width: 32px;
        height: 32px;
        margin-right: 10px;
        
        background: ${props =>`url(${require(`../../assets/images/img_icon_avatar_${props.idx}.png`)}) no-repeat left top`};
    }
`;

const COM101F4 = () => {

    const backAnimatedItem = useScrollFadeIn('',1.5);

    const [boxes, setBoxes] = useState([
        {idx:1, title:'“우리 가족을 위한 앱”', content:'이 건강관리 앱은 단순히 내 건강만 관리해주는 것이 아니라, 가족 모두의 건강 상태를 함께 챙길 수 있는 기능이 있어서 정말 만족스럽습니다!'},
        {idx:2, title:'“내 건강을 잘 알게 됐어요”', content:'이 건강관리 앱을 사용하면서 건강을 관리하는 습관이 자연스럽게 생겼어요. 매일 내 몸의 상태를 기록하고 확인하다 보니, 이제는 내 건강 데이터를 기반으로 내가 어떤 상태인지 스스로 더 잘 알게 됐습니다.'},
        {idx:3, title:'“내가 먹는 약, 이제는 알아요”', content:'이 앱은 내가 먹는 약에 대한 정보를 정말 쉽고 정확하게 알려줘서 큰 도움을 받고 있어요.'},
        {idx:4, title:'“병원 선택, AI가 도와줘요”  ', content:'아플 때 어느 병원에 가야 할지 막막했던 경험이 한두 번이 아니었는데, 이 앱 덕분에 그런 고민이 크게 줄었어요. AI가 내 증상과 상황을 분석해서 가장 적합한 병원이나 진료 과목을 추천해주니까, 이제는 시간 낭비 없이 바로 필요한 진료를 받을 수 있게 되었죠'},
        {idx:5, title:'“건강기록, 한 곳에!”', content:'건강검진을 받을 때마다 결과 기록을 관리하기 힘들어서 늘 불편했는데, 이 앱 덕분에 그런 걱정이 싹 사라졌어요. 건강검진 결과뿐 아니라 혈압, 혈당, 체중 같은 주요 건강 데이터를 자동으로 저장하고 언제든 확인할 수 있으니 정말 편리합니다.'},
        {idx:6, title:'“가까운 병원·약국, 바로 찾기!”', content:'이 앱은 주변 병원과 약국을 빠르고 정확하게 찾아줘서 정말 편리해요.'},
        {idx:7, title:'“유익한 건강 카드뉴스”', content:'"건강 관련 카드뉴스를 제공해줘서 보는 재미가 쏠쏠합니다! 알기 쉽게 정리된 건강 정보와 생활 속 꿀팁을 카드 형식으로 제공하니 읽기도 편하고 기억에 오래 남아요.'},
        {idx:8, title:'“우리 아이 건강챙기기”', content:'초보 엄마로서 아이의 예방접종과 건강검진 일정을 관리하는 게 쉽지 않았는데, 이 앱 덕분에 큰 걱정 없이 체계적으로 관리할 수 있어서 정말 만족하고 있어요.'},
    ]);

    //데이터 섞기
    const shuffledBoxes = [...boxes].sort(() => Math.random() - 0.5);

    // 그리드 레이아웃 설정
    const breakpointColumnsObj = {
        default: 4, // 기본 4개 열
        1416: 3,    // 342px * 3 + 16px * 3
        1058: 2,     // 342px * 2 + 16px * 2
        700: 1,     // 342px * 1 + 16px * 2
    };


    return (
        <div className={style.sec04_ctn} {...backAnimatedItem}>
            <div className={style.sec04_title_box}>
                <div className={style.main_title}>
                    실제 사용자들의 간단 리뷰
                </div>
                <div className={style.sub_title}>
                    실제 사용자들의 <span>생생한 리뷰</span>를 만나보세요!
                </div>
            </div>
            <div className={style.sec04_content}>
                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className={style.masonryGrid}  // 스타일 적용
                    columnClassName={style.masonryCol}  // 스타일 적용
                >
                    {shuffledBoxes.map((box, index) => (
                        <div key={index} className={style.masonryBox}>
                            <StdTitle idx={String(box.idx).padStart(3,'0')}>{box.title}</StdTitle>
                            <hr/>
                            <p>{box.content}</p>
                        </div>
                    ))}
                </Masonry>

                <div className={style.downInfoBar}>
                    <div className={style.downInfo_sub_title}>
                        DOWNLOAD
                    </div>
                    <div className={style.downInfo_main_title}>
                        헬스스캔을 지금 바로 만나보세요.
                    </div>
                    <div className={style.btnGrp}>
                        <button
                            className={style.btn_google}
                            onClick={() => window.open("https://play.google.com/store/apps/details?id=kr.co.tilde.healthscan")}>{/*google*/}</button>
                        <button
                            className={style.btn_appStore}
                            onClick={() => window.open("https://apps.apple.com/us/app/id1608855676")}>{/*AppStore*/}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default COM101F4;
