import React from 'react';
import icons from "assets/images/img_icon_grp.svg";

const Icon = ({ id, width = 20, height=24, color = 'black', viewBox }) => (
    <svg
        width={width}
        height={height}
        fill={color}
        //viewBox={'0 0 '+width+' '+height}
    >
        <use xlinkHref={`${icons}#${id}`} />
    </svg>
);

export default Icon;