import React from "react";
import ScrollContainer from "react-indiana-drag-scroll";

import style from "./COM101F2.module.scss";


const COM101F2 = () => {

    const cards = [
        { id: 1, title: "STEP 1", image: require("../../assets/images/sec02_slide_img_001.png")},
        { id: 2, title: "STEP 2", image: require("../../assets/images/sec02_slide_img_002.png")},
        { id: 3, title: "STEP 3", image: require("../../assets/images/sec02_slide_img_003.png")},
        { id: 4, title: "STEP 4", image: require("../../assets/images/sec02_slide_img_004.png")},
    ];

    return (
        <div className={style.sec02_ctn}>
            <div className={style.sec02_title_box}>
                <div className={style.main_title}>
                    간편하게 기록하자, 헬스스캔
                </div>
                <div className={style.sub_title}>
                    핸드폰 카메라를 이용하여 <span>건강기록문서</span>를 찍으면 문자를 스캔하여 데이터로 저장합니다.
                </div>
            </div>
            {/*<div className={style.sec02_content}>내용 BG</div>*/}
            <div className={style.cardSB_cont}>
                <ScrollContainer className={style.scroll_cont}>
                    {cards.map((card) => (
                        <div key={card.id} className={style.card}>
                            <img src={card.image} alt={card.title}/>
                        </div>
                    ))}
                </ScrollContainer>
            </div>
        </div>
    );
};

export default COM101F2;
