// src/routes.js
import React from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';

import COM001L1 from "pages/COM/COM001L1";

import COM001F1 from "pages/COM/COM001F1";
import COM002F1 from "pages/COM/COM002F1";
import Loading from "config/Loading";
import {ToastContainer} from "react-toastify";

const MainRoutes = () => {

    return (
        <Router>
            <COM001F1/>  {/*Header**/}
            <Routes>
                <Route path="/" element={<COM001L1/>}/>
                {/*<Route path="/" element={<COM101F6/>}/>*/}
                {/*<Route path="/" element={<COM901F1/>}/>*/}
                <Route path="*" element={<Navigate to="/"/>}/>
            </Routes>
            <COM002F1/>  {/*Footer/*/}
            <Loading />
            <ToastContainer position="bottom-center"/>
        </Router>
    );
};

export default MainRoutes;