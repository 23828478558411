import React, {useRef, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react"

import Icon from "components/Icon/Icon";

import style from './COM201F5.module.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import {Navigation, Pagination} from 'swiper/modules';

const COM201F5 = () => {

    const sec05_swiperRef = useRef(null);

    const [activeIndex, setActiveIndex] = useState(0);

    const handleSlideTo = (index) => {
        if (sec05_swiperRef.current) {
            sec05_swiperRef.current.slideTo(index); // 특정 슬라이드로 이동
        }
    };

    const [slides, setSlides] = useState([
        {title:"본인확인 QR 코드",content:`의료진 전용 헬스스캔 Med 플랫폼을 통해 <span>환자 열람 동의</span> “본인확인 QR”코드를 입력 후 환자 건강기록 열람 화면을 통해 <span>가정 혈압/혈당, 건강기록, 처방기록 등의 정보</span>를 확인할 수 있습니다.`},
        {title:"기간별 건강기록 확인 가능",content:`개인 환자가 입력한 건강기록에 대해 <span>기간별 조회 후 시계열 그래프와</span>일자별 상세 정보 확인이 가능하며, 기간별 Summary 창을 선택 시 자동복사 되어 <span>EMR 차트에 간편하게 입력가능</span> 합니다.`},
        {title:"임상의사결정지원시스템 (CDSS)",content:`데이터 기반 지식에 기초하여 <span>임상의나 의료 전문가</span>의 의사결정을 직접 지원할 수 있는 시스템으로 환자의 안전을 위해 <span>효과적인 처방 및 약물 사용 정보</span>를 가이드 함으로써 의료 서비스의 질을 한 차원 높일 수 있습니다.`},
    ]);

    return (
        <div className={style.sec05_cnt}>
            <div className={style.sec05_title_box}>
                <div className={style.main_title}>
                    <span>의료진용</span> 통합 플랫폼, 헬스스캔 메드
                </div>
                <div className={style.sub_title}>
                    헬스스캔 개인 사용자의 <span>건강데이터를 조회</span>할 수 있는 개인 동의기반 서비스입니다.
                </div>
            </div>
            <div className={style.sec05_content}>
                <div className={style.slide_box}>
                    <Swiper className={style.swiper}
                            key="sec05_swiper"
                            onSwiper={(swiper) => {
                                sec05_swiperRef.current = swiper; // Swiper 인스턴스 저장
                            }}
                            spaceBetween={50}
                            slidesPerView={1} // 슬라이드를 한 화면에 표시할 게수

                            modules={[Pagination, Navigation]}

                            onSlideChange={(swiper) => {
                                setActiveIndex(swiper.activeIndex);
                            }}

                    >
                        {slides.map((slide, index) => {

                                const pageNo = index + 1;

                                return (
                                    <SwiperSlide className={style.swiperSlide}>
                                        <div className={style.slide_cont}>
                                            <div className={style.cont_img}>
                                                <div className={style[`cont_img_bg${String(pageNo).padStart(2, '0')}`]}>{/*HealthScan앱 이미지*/}</div>
                                            </div>
                                            <div className={style.cont_textBox}>
                                                <div className={style.messBox}>
                                                    <h3>{slide.title}</h3>
                                                    <hr/>
                                                    <p dangerouslySetInnerHTML={{__html: slide.content}}/>
                                                </div>

                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            }
                        )}
                        <div className={style.nav_bar}>
                            <button onClick={() => handleSlideTo(activeIndex-1)}>
                                <Icon id="arrow_left_01" width="24" height="24"/>
                            </button>
                            <span>{String(activeIndex+1).padStart(2, '0')}</span> {"/ " + String(slides.length).padStart(2, '0')}
                            <button onClick={() => handleSlideTo(activeIndex+1)}>
                                <Icon id="arrow_left_02" width="24" height="24"/>
                            </button>
                        </div>
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default COM201F5;
