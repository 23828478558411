import React, {useRef, useState} from "react";

import style from './COM101F6.module.scss';
import Icon from "components/Icon/Icon";
import toast from "components/Toast/Toast";
import {postData} from 'utils/TransactionUtil';
import Button from "../../components/Button/Button";

const COM101F6 = ({isMobile= false}) => {

    const [isExpanded, setIsExpanded] = useState(false);
    const [isTermAgree, setIsTermAgree] = useState(false);


    const [contactUsData, setContactUsData] = useState([
        {name:"user", label: "이름", type: "text", value: "", error: "", maxLength:"50"},
        {name:"company", label: "회사명(병원명)", type: "text", value: "", error: "" , maxLength:"100"},
        {name:"tlNo", label: "연락처", type: "text", value: "", error: "" , maxLength:"15"},
        {name:"reEmail", label: "이메일", type: "text", value: "", error: "", maxLength:"100"},
        {
            name:"inqCate", label: "카테고리", type: "radio", value: "헬스스캔", error: "", maxLength:"100",
            subField: [
                { label: "헬스스캔", value: "헬스스캔" },
                { label: "헬스스캔 메드", value: "헬스스캔 메드" },
                { label: "서비스 사용 및 제휴에 대한 문의", value: "서비스 사용 및 제휴에 대한 문의" },
                { label: "기타", value: "기타" },
            ],
        },
        {name:"subject", label: "제목", type: "text", value: "", error: "", maxLength:"100"},
        {name:"inquiryCont", label: "문의내용", type: "textarea", value: "", error: "", maxLength:"2000"},
    ]);

    const refs = useRef({});

    const validate = () => {
        let valid = true;

        const updatedFields = contactUsData.map((field) => {
            let error = "";
            if (!field.value.trim()) {
                error = `${field.label}을(를) 입력해주세요.`;
                valid = false;
            } else if (field.name === "reEmail" && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(field.value)) {
                error = "유효한 이메일 형식이 아닙니다.";
                valid = false;
            }

            return { ...field, error };
        });

        setContactUsData(updatedFields);

        if (!valid) {
            const firstErrorField = updatedFields.find((field) => field.error);
            if (firstErrorField) {
                toast({ type: 'info', message: firstErrorField.error, opt: { position: "top-center"} });
                refs.current[firstErrorField.name]?.focus();  // 포커스를 해당 필드로 이동
            }
        }

        // 동의 여부 체크
        if(!isTermAgree){
            toast({ type: 'info', message: "동의여부를 체크 하세요", opt: { position: "top-center"} });
            valid = false;
        }

        return valid;
    };

    const toggleExpansion = () => {
        setIsExpanded((prev) => !prev);
    };


    const handleClick_contactUs = () =>{
        // toast({ type: 'info', message: '준비중입니다.', opt: { position: "top-center"} });
        // return;

        if(validate()){
            actionContactUsSend();
        }
    };

    const handleChange = (e, index) => {

        const { name, value } = e.target;
        setContactUsData((prevData) =>
            prevData.map((item, i) =>
                i === index ? { ...item, value: value } : item
            )
        );
    };
    const handleCheckChange = (e, index, optionValue) => {
        const { checked } = e.target;
        setContactUsData((prevData) =>
            prevData.map((item, i) =>
                i === index
                    ? {
                        ...item,
                        value: checked
                            ? [...item.value, optionValue]
                            : item.value.filter((val) => val !== optionValue),
                    }
                    : item
            )
        );
    };



    async function actionContactUsSend(){

        let param = {
            ...contactUsData.reduce((acc, field) => ({ ...acc, [field.name]: field.value }), {})
        }

        postData("/api/com/contactUsSend",param).then((data) => {

            if (data.code === "000") {
                toast({ type: 'info', message: '전송 완료 하였습니다.', opt: { position: "top-center"} });

                setContactUsData((prevData) =>
                    prevData.map((item) => ({
                        ...item,
                        value: item.type === "check" ? [] : "", // 체크박스는 빈 배열로 처리
                    }))
                );
            }else{
                alert("전송에 실퍠하였습니다.");
            }
        });
    }

    return (
        <div className={style.sec06_cnt}>
            <div className={style.sec06_title_box}>
                <div className={style.main_title}>
                    Contact Us
                </div>
                <div className={style.sub_title}>
                    헬스스캔, 헬스스캔 Med와의 <span>서비스 사용 및 제휴에 대한 문의, 제안</span>을 남겨주시면 빠른 연락드리겠습니다.
                </div>
            </div>
            <div className={style.sec06_content}>
                <hr/>
                <div className={style.inputArea}>
                    {contactUsData.map((field, index) => (
                        <div className={style.inputArea_item}>
                            <h3>{field.label}</h3>
                            {field.type === "text" && (
                                <input
                                    name={field.label}
                                    type={field.type}
                                    maxLength={field.maxLength}
                                    placeholder={field.label+"을 입력해 주세요."}
                                    value={field.value}
                                    onChange={(e) => handleChange(e, index)}
                                    ref={(el) => (refs.current[field.name] = el)} // ref 동적 할당
                                />
                            )}
                            {/* Textarea */}
                            {field.type === "textarea" && (
                                <textarea
                                    id={field.name}
                                    name={field.name}
                                    value={field.value}
                                    maxLength={field.maxLength}
                                    placeholder={field.label+"을 입력해 주세요."}
                                    onChange={(e) => handleChange(e, index)}
                                    ref={(el) => (refs.current[field.name] = el)} // ref 동적 할당
                                ></textarea>
                            )}

                            {/* Radio Buttons */}
                            {field.type === "radio" && (
                                <div className={style.fieldRadio}>
                                    {field.subField?.map((option, subIndex) => (
                                        <div key={subIndex} className={style.radioCont}>
                                            <input
                                                type="radio"
                                                id={`${field.name}-${option.value}`}
                                                name={field.name}
                                                value={option.value}
                                                checked={field.value === option.value}
                                                onChange={(e) => handleChange(e, index)}
                                            />
                                            <label htmlFor={`${field.name}-${option.value}`}>{option.label}</label>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {/* Checkboxes */}
                            {field.type === "check" && (
                                <div>
                                    {field.subField?.map((option, subIndex) => (
                                        <div key={subIndex}>
                                            <input
                                                type="checkbox"
                                                id={`${field.name}-${option.value}`}
                                                name={field.name}
                                                value={option.value}
                                                checked={field.value.includes(option.value)}
                                                onChange={(e) => handleCheckChange(e, index, option.value)}
                                            />
                                            <label htmlFor={`${field.name}-${option.value}`}>{option.label}</label>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {/*{field.error && <p className={style.error}>{field.error}</p>}*/}
                        </div>
                    ))}
                </div>
                <div className={style.termsArea}>
                    {/*<hr/>*/}
                    <div className={style.termsAreaCtl}>
                        <input type="checkbox" id="termsChk" checked={isTermAgree} onChange={e => {
                            setIsTermAgree(!isTermAgree)
                        }}/>
                        <label htmlFor="termsChk">(필수) 개인정보 수집 및 이용동의에 관한 사항</label>

                        <button onClick={toggleExpansion}>
                            {isMobile}
                            <Icon id={isExpanded ? "chevronUp" : "chevronDown"} width={isMobile?"24":"32"} height={isMobile?"24":"32"}/>
                        </button>
                    </div>
                    {isExpanded && (
                        <div className={style.termsCont}>
                            <h2>문의 및 제안을 위해 담당자 이름과 연락처, 이메일 정보를 수집에 대한 동의 안내</h2>
                            <p>개인정보 수집 및 이용에 대한 안내</p>
                            <ul>
                                <li><span>수집항목:</span> 성명, 회사명(병원명), 연락처, 이메일, 그 외 개인이 직접 등록한 문의 내용</li>
                                <li><span>수집목적:</span> 문의 결과 회신</li>
                                <li><span>이용기간:</span> 개인정보 수집 및 이용에 대한 목적이 달성된 후에는 해당 정보를 파기</li>
                            </ul>
                        </div>
                    )}
                    <div className={style.btnGrp}>
                        <Button
                            variant={isTermAgree ? "primary" : "success"}
                            size="lg"
                            addStyle={{width: "150px", height: "46px"}}
                            onClick={handleClick_contactUs}
                        >
                            문의하기
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default COM101F6;
